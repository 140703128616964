/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import { SEO, ParseContent, GravityForm } from 'components/shared'
import Layout from 'components/layout/Layout'
import locationIcon from 'img/icon-location2.svg'
import mailIcon from 'img/icon-mail2.svg'
import phoneIcon from 'img/icon-phone2.svg'
import Plaatjie from '@ubo/plaatjie'

const Hero = styled.section`
  height: 470px;
  background-repeat: no-repeat;

  @media screen and (max-width: 991px) {
    background-size: cover !important;
  }
`

const HeroContent = styled.div`
  padding-top: 154px;
  height: 470px;
`

const Feature = styled.div`
  font-family: ${(props) => props.theme.font.family.bold};
`

const FeatureDescription = styled(ParseContent)`
  font-size: ${props => props.theme.font.size.sm};

  & p {
    margin: 0;
  }
`

const Form = styled.div`
  position: relative;
  margin-top: -180px;
  border-radius: 25px;
  box-shadow: 0 0 4px rgba(0,0,0,0.5);
  background: white;
  padding: 50px;
  width: 100%;

  @media screen and (max-width: 992px){
    padding: 20px;
    margin-top: -220px;
  }
`

const Map = styled.div`
  border-radius: 20px;
  border: 1px solid #979797;
  height: 370px;
  margin-top: 30px;
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout hasNewsletter={false}>
      <SEO yoast={yoast} path={path} article />

      <Hero className="hero-image" style={{
          backgroundImage: `url(${acf.banner.background.localFile.childImageSharp.fluid.src})`
        }}>
        <HeroContent />
      </Hero>

      <div className="container pb-5">
        <Form>
          <div className="d-flex flex-wrap">

            <div className="col-lg-6 pr-lg-5">
              <ParseContent content={acf.meta.description} />

              <div className="pt-1">
                <Feature className='d-flex align-items-center ml-lg-4 my-4'>
                  <img src={locationIcon} alt='location' />
                  <div className='ml-3'>
                    <FeatureDescription content={acf.info.description_address} />
                  </div>
                </Feature>
                <Feature className='d-flex align-items-center ml-lg-4 my-3'>
                  <img src={mailIcon} alt='mail address' />
                  <div className='ml-3'>
                    <FeatureDescription content={acf.info.description_email} />
                  </div>
                </Feature>
                <Feature className='d-flex align-items-center ml-lg-4 my-3 mb-5'>
                  <img src={phoneIcon} alt='phone number' />
                  <div className='ml-3'>
                    <FeatureDescription content={acf.info.description_phone} />
                  </div>
                </Feature>
              </div>
            </div>
            <div className="col-lg-6">
              <GravityForm id={1} />
            </div>
          </div>

          <Map>
            <iframe
              width="100%"
              height="100%"
              src={acf.map.embed_url}
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              style={{borderRadius: 20}}
              title='map'
            />
          </Map>
        </Form>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        meta {
          description
        }

        banner {
          background {
          localFile {
            childImageSharp {
              fluid(toFormat: WEBP, maxWidth: 2000, quality:100) {
                src
              }
            }
          }
        }
        }

        info {
          description_address
          description_email
          description_phone
        }

        map {
          embed_url
        }
      }
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate